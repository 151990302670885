import gql from 'graphql-tag'

export const GET_WHATSON_BY_TYPE = gql`
    query GET_WHATSON_BY_TYPE (
        $type: String
    ) {
        events(
            where: {
                taxQuery: {
                    taxArray: [{
                        operator: IN
                        taxonomy: TYPE
                        terms: [$type]
                        field: SLUG
                    }]
                }
            }
        ) {
            edges {
                node {
                    title
                    link
                    slug
                    date
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                    eventFields {
                        date
                        displayDate
                        excerpt
                        slideshowImage {
                            mediaItemUrl
                        }
                    }
                    types {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_NEWS_BY_CAT = gql`
    query GET_NEWS_BY_CAT (
        $category: String
    ) {
        posts(
            where: {
                taxQuery: {
                    relation: AND
                    taxArray: [
                        {
                            operator: IN
                            taxonomy: CATEGORY
                            terms: [$category]
                            field: SLUG
                        },
                        {
                            operator: NOT_IN
                            taxonomy: TAG
                            terms: "featured"
                            field: SLUG
                        }
                    ]
                }
            }
        ) {
            edges {
                node {
                    title
                    link
                    slug
                    date
                    content(format: RENDERED)
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                    newsFields {
                        date
                        excerpt
                    }
                    categories {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                    tags {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

export const SUBMIT_FORM = gql`
    mutation SUBMIT_FORM (
        $data: String
        $type: String
    ) {
        submitForm (
            input: {
                data: $data
                type: $type
                clientMutationId: "uniqueId"
            }
        ) {
            success
        }
    }
`

export const SUBMIT_CHRISTMAS_ENTRY = gql`
    mutation SUBMIT_CHRISTMAS_ENTRY (
        $name: String
        $email: String
    ) {
        submitChristmasEntry (
            input: {
                name: $name
                email: $email
                clientMutationId: "uniqueId"
            }
        ) {
            success
            result
            prize
            prizeImage
            code
            name
        }
    }
`

export const CHECK_CHRISTMAS_WINNER = gql`
    mutation CHECK_CHRISTMAS_WINNER (
        $name: String
    ) {
        checkChristmasWinner (
            input: {
                name: $name
                clientMutationId: "uniqueId"
            }
        ) {
            success
            result
            prize
            prizeImage
        }
    }
`

export const CHECK_CHRISTMAS_CODE = gql`
    mutation CHECK_CHRISTMAS_CODE (
        $code: String
    ) {
        checkChristmasCode (
            input: {
                code: $code
                clientMutationId: "uniqueId"
            }
        ) {
            success
            prize
            expiryDate
            code
            redeemed
        }
    }
`

export const REDEEM_CHRISTMAS_CODE = gql`
    mutation REDEEM_CHRISTMAS_CODE (
        $code: String
    ) {
        redeemChristmasCode (
            input: {
                code: $code
                clientMutationId: "uniqueId"
            }
        ) {
            success
            prize
            expiryDate
            code
            redeemed
        }
    }
`

export const FormFieldsFragment = graphql`
    fragment FormFieldsFragment on WPGraphQL_Form {
    formFields {
        sections {
            title
            rowsHeading
            copy
            rows {
                heading
                columns {
                    label
                    options
                    type    
                }
            }
        }
        buttonLabel
        checkboxConfirmCopy
        disclaimer
    }
}
`

export const GET_WHATSON = gql`
    query GET_WHATSON {
        events {
            nodes {
                title
                slug
                content(format: RENDERED)
                featuredImage {
                    node {
                        mediaItemUrl
                    }
                }
                eventFields {
                    excerpt
                    slideshowImage {
                        mediaItemUrl
                    }
                }
            }
        }
    }
`

export const GET_SEARCH_RESULTS = gql`
    query GET_SEARCH_RESULTS (
        $term: String
    ) {
        contentNodes(where: {search: $term}, first: 100) {
            nodes {
                __typename
                id
                link
                uri
                ... on Post {
                    title
                    content(format: RENDERED)
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
                ... on Page {
                    title
                    content(format: RENDERED)
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
                ... on Place {
                    title
                    content(format: RENDERED)
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
                ... on Event {
                    title
                    content(format: RENDERED)
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
                ... on Development {
                    title
                    content(format: RENDERED)
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    }
`
